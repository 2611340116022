import React from 'react'
import { graphql } from 'gatsby'

import { Parallax  } from 'react-parallax';
import { Link } from 'gatsby'
// import FixedHeader from '../components/FixedHeader'
import Content from '../components/Content.js'
import Layout from '../components/Layout.js'

import './AboutPage.scss'


// Export Template for use in CMS preview
export const AboutPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  section1,
  section2
}) => (
  <main>
    
    <Parallax bgImage={featuredImage+'-/progressive/yes/-/format/auto/-/resize/2000x/'} strength={500}>
      <div className="container parallaxHero">
          <div>
            <p>For over 60 years we have been reliably meeting the transport needs of businesses and individuals in all areas of Sydney, Newcastle and the Central Coast with a friendly, can-do approach. Our trucks can transport up to 24 pallets at a time with ease, making Pelican big and experienced enough to handle large or complex jobs but small enough to have strict quality-control measures in place to ensure your Pelican experience is one you would recommend to a friend.</p>
          </div>
      </div>
    </Parallax>    

    <section className="section">
      <div className="container">
        <Content source={section1} />
      </div>
    </section>
    

    <section className="section text-center">
      <div className="container">
        <Content source={section2} />
        <Link to="/quick-quote/" className="Button">
        Quick Quote
        </Link>
      </div>
    </section>
    
  </main>
)

const AboutPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <AboutPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        section1
        section2
      }
    }
  }
`
